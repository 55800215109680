import { render, staticRenderFns } from "./DefaultColor.vue?vue&type=template&id=c47a4334&scoped=true&"
import script from "./DefaultColor.vue?vue&type=script&lang=js&"
export * from "./DefaultColor.vue?vue&type=script&lang=js&"
import style0 from "./DefaultColor.vue?vue&type=style&index=0&id=c47a4334&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c47a4334",
  null
  
)

export default component.exports